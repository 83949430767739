import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import mixpanel from 'mixpanel-browser';

Sentry.init({
	dsn: 'https://ebab8726c02a8605dc4b17f64c745ef1@o4505885059776512.ingest.sentry.io/4505885081993216',
	tracesSampleRate: 1.0,
	// debug: true,
	environment: import.meta.env.MODE,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay()],
});
 
let token = '62e8d78f5b4e537ec9dee6ec7cbe98d0'
if(import.meta.env.MODE == 'production') {
	token = 'f4b44b088b4a759d1aa1055265e87436'
}

// Near entry of your product, init Mixpanel
mixpanel.init(token, {debug: false, track_pageview: true, persistence: 'localStorage', ignore_dnt: true});
mixpanel.identify(Math.floor(Math.random() * 11) + 1)

//console.log('inside hooks.client.ts', import.meta.env.MODE);

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
